:root {
    --inferno-0: #000004;
    --inferno-1: #280B54;
    --inferno-2: #65156E;
    --inferno-3: #9F2A63;
    --inferno-4: #D44842;
    --inferno-5: #F57D15;
    --inferno-6: #FAC127;
    --inferno-7: #FCFFA4;
    --off-white: #FFFFFF;
    height: 100vh;
}

.app-background {
    position: fixed;
    top: 0;
    left: 0;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
}

/* background image */
.app-background-gradient {
    background: linear-gradient(234.58deg, #0077A0 12.42%, #002C77 70.78%);
    position: fixed;
    top: 0;
    left: 0;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
}

.flex-container {
    /* display: flex; */
    /* justify-content: center; */
    font-size: 17px;
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.8);
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-attachment: fixed;
    position: fixed;
}

.nav-margin {
    margin-top: 45px;
}

.header {
    /* display: flex;
  justify-content: center; */
    margin-top: 5rem;
    font-size: 16px;
    color: #dfdfdf;
}

.blasting-button {
    margin-top: 2vh;
    margin-left: 25vw;
}

.dark_mode_text {
    color: #dfdfdf;
    background-color: rgb(34, 34, 34);
}

.nav-dropdown>a {
    color: #fff !important;
    border-radius: 5px;
}

.upload_cards {
    line-height: 5;
}


/* Remove hyperlink underline and highlight for all links */

.remove_link_hover {
    text-decoration: none;
}

.remove_link_hover:hover {
    color: #dfdfdf;
    text-decoration: none;
}

.table-logo {
    background-color: transparent !important;
    border-color: transparent !important;
    color: inherit !important;
}

.red-hover-logo:hover {
    color: rgb(255, 106, 106) !important;
}

.download-logo:hover {
    color: rgb(0, 166, 255) !important;
}

.create-params-button {
    float: right;
    margin-top: 5vh;
    margin-right: 10vh;
}


/* Scrollbar stuff */


/* width */

::-webkit-scrollbar {
    width: 8px;
}

.params_margin_padding {
    width: 40vw;
    margin: 2vh;
    padding: 4vh;
    display: grid;
    grid-template-columns: auto auto;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #656565;
    border-radius: 5px;
}

.param-form {
    height: 28px;
    width: 130px;
    border-radius: 3px;
    border: none;
}

.error-font {
    color: #EF4E45;
}

.button-border-white {
    border-color: #bdbdbd !important;
}

.form-error {
    border-width: 1px 1px 1px 10px;
    border-style: solid;
    border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144);
    border-image: initial;
    background: rgb(251, 236, 242);
    transition: all 0.3s;
}

.little-top-margin {
    margin-top: 3px;
}

.medium-top-margin {
    margin-top: 5px;
}

.big-top-margin {
    margin-top: 10px;
}

.float-right {
    float: right;
}

.little-margin {
    margin-top: .6vh;
    margin-bottom: .6vh;
}

.margin-bottom {
    margin-bottom: 10px;
}

.margin-left {
    margin-left: 3px;
}

.overflowY {
    overflow-y: auto;
}

.font-size-small {
    font-size: small;
}

.submit-params-button {
    float: right;
    margin-top: 2vh;
    margin-left: 1vw;
    width: 100px;
}

.form-fieldset {
    width: 20vw;
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: threedface;
    border-image: initial;
}

.field-set {
    display: grid;
    grid-template-columns: auto 120px;
    row-gap: 0.4vh;
    column-gap: 0.2vw;
}

.float-right {
    text-align: right;
}

hr {
    background-color: white;
    margin: 1vh !important;
}


.sidenav {
    background-color: #F9F9F9;
    width: 345px !important;
    padding: 1px 14px;
    height: 100vh;
}

.sidenav-tabs {
    display: flex;
    justify-content: space-between;
    margin-top: 1vh;
    margin-bottom: 1vh;
    padding-bottom: 5px;
    border-bottom: 1px solid #8096B2;
}

.parameter-input {
    height: 28px;
    width: 100px;
    text-align: right;
    background-color: inherit;
    color: inherit;
    border-radius: 4px;
    border: 1px solid rgb(161, 161, 161);
}

/*remove step buttons*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}


.font-dark-blue {
    color: #002C77;
}

.font-light-blue {
    color: #8096B2;
}

.cursor-pointer {
    cursor: pointer;
}

.primary-button {
    background: #009DE0;
    color: #FFFFFF;
    border: none;
    padding: 6px;
    width: 100%;
    border-radius: 3px;
}

.secondary-button {
    background: #FFFFFF;
    color: #009DE0;
    border: 1px solid #009DE0;
    padding: 6px;
    width: 100%;
    border-radius: 3px;
}

.delete-button {
    background: #EF4E45;
    color: #FFFFFF;
    border: 1px solid #EF4E45;
    padding: 6px;
    width: 100%;
    border-radius: 3px;
}

.margin-top {
    margin-top: 10px;
}

.navbar {
    background-color: #002C77;
    height: 45px;
}

.navbar>a {
    color: #FFFFFF !important;
}

.job-item {
    border: 1px solid #002C774D;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
}

.highlighted-job-item {
    background-color: #d8e8fd;
}

.caseBackground {
    background: #F9F9F9;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
}

.caseList {
    display: grid;
    grid-template-columns: 280px 280px 280px 280px;
}

.caseItem {
    width: 250px;
    margin: 3vh;
    padding: 5px;
    border: 1px solid #002C774D;
    background: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    border-radius: 5px;
    cursor: pointer;
}

.caseItem>h5 {
    padding: 5px;
    margin-bottom: 0px;
}

.caseItem>div {
    padding-left: 5px;
    color: #8096B2;
    font-size: smaller;
}

.city-map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 345px;
    width: 100%;
}

/* file upload button */
input[type=file]::file-selector-button {
    background: #009DE0;
    color: #FFFFFF;
    border: 1px solid #009DE0;
    padding: 6px;
    border-radius: 3px;
}

.flex-container {
    display: flex;
    justify-content: center;
    font-size: 17px;
    background: #F9F9F9;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-attachment: fixed;
    position: fixed;
}

.page-specs {
    height: 93vh;
    width: 75rem;
    padding: 20px;
    margin: 10px;
}

.table-header {
    background: #002C77;
    color: #FFFF;
}

.table-header {
    background: #002C77;
    color: #FFFF;
}

.table-header>th {
    font-weight: 400;
    padding-left: 20px;
}

.table-body tr>td {
    padding-left: 20px;
}

.table-body tr:nth-child(even) {
    background-color: #8096b261;
}

.table-body tr:nth-child(odd) {
    background-color: #FFFFFF;
}

.pagination>li>span {
    color: #fff;
    background-color: #009DE0 !important;
    border-color: #009DE0 !important;
}

.badge {
    border-radius: 3px;
}

.badge-success {
    background-color: #00AC41;
}

.badge-warning {
    background-color: #FFBE00;
    color: #002C77 !important;
}

.badge-danger {
    background-color: #EF4E45;
}

.badge-info {
    background-color: #009DE0;
}

#invisible-file-upload {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.file-upload {
    padding: 3px;
    cursor: pointer;
    display: inline-block;
    width: 30%;
}

.file-name {
    display: inline-block;
    width: 180px;
    margin-left: 10px;
    position: relative;
    top: 2px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.disabled-button {
    border: 1px solid #acacac;
    background-color: #cccccc;
    color: #666666;
}

.flex {
    display: flex;
}

.dashTable-container {
    display: inline-block;
    overflow-y: scroll;
    max-height: 530px;
}